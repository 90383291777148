.radio-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e8eb;
  border-radius: 5px;
  padding: 10px;
  padding: 10px;
}

.custom-radio {
  width: 18px;   /* Increase the width */
  height: 18px;  /* Increase the height */
  cursor: pointer;
 // margin-right: 10px;  /* Space between the radio button and the label */
 margin-top: -5px;
}

.radio-option {
  display: flex;
  align-items: center;
  margin-right: 15px; /* Space between radio options */
}

/* For changing the background for auto fill data   */
:root {
  --autofill-dark-mode-color: #1A202C;
  --autofill-light-mode-color: #e8f0fe;
  --text-fill-dark-mode: #f1f1f1;
  --text-fill-light-mode: #000000;
}

.dark-only {
  --autofill-color: var(--autofill-dark-mode-color);
  --text-fill-color: var(--text-fill-dark-mode);
}

.light-only {
  --autofill-color: var(--autofill-light-mode-color);
  --text-fill-color: var(--text-fill-light-mode);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 1px solid #434346;
  -webkit-text-fill-color: var(--text-fill-color);
  -webkit-box-shadow: 0 0 0px 1000px var(--autofill-color) inset;
  background-color: transparent;
  transition: background-color 5000s ease-in-out 0s,
  -webkit-text-fill-color 0.3s ease-in-out;
}

