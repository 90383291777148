.radio-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e8eb;
  border-radius: 5px;
  padding: 10px;
  padding: 10px;
}

.custom-radio {
  width: 18px;   /* Increase the width */
  height: 18px;  /* Increase the height */
  cursor: pointer;
 // margin-right: 10px;  /* Space between the radio button and the label */
 margin-top: -5px;
}

.radio-option {
  display: flex;
  align-items: center;
  margin-right: 15px; /* Space between radio options */
}